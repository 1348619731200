import axios from "axios";
import { CUSTOM_FONTS } from "../components/design-editor/constants/custom-fonts";

// export const SOCKET_ENDPOINT = "http://127.0.0.1:5000";
export const SOCKET_ENDPOINT = "https://dev-api.plutis.io";
// export const SOCKET_ENDPOINT = "http://192.168.4.30:5000";
// export const SOCKET_ENDPOINT = "http://192.168.1.69:5000";
export const API_ENDPOINT = `${SOCKET_ENDPOINT}/api`;
// export const API_ENDPOINT = "http://192.168.5.204:5000/api";

// export const STRIPE_PUBLISHABLE_KEY = 'pk_test_51M5HYBFt8SF8cRVQIfH0p6XObDRme4gOTNFLr1NGWijK4SZziQ0ctZOKN4qaFgEvbnerkNj4mqGASpjBvqSCDKb500jCgcaT4X';
export const STRIPE_PUBLISHABLE_KEY = 'pk_live_51M5HYBFt8SF8cRVQeDTnyzeywWtDgbUJkdXA3nK6DhPsLBJdMCOOXYXbzyH9loJESTQgOKJcBNImPdkHQbRLdXQb00G24t0uEA';
export const EMBEDDED_MERCHANT_ONBOARDING_FORM = "https://test-mlp-portal.marketleap.io/signup/Marketleap";

const allowedPathsByAccountType = {
  "agency": ["/integrations", "/dashboard"],
  "root": ["/integrations", "/dashboard", "/lead-generation", "/creative-suite", "/marketing", "/review-management"],
  "subaccount": ["/dashboard", '/marketing', '/marketing/inbox']
}

function toTwilioCredentials(authentication) {

  return { username: authentication.accountSid, password: authentication.authToken }
}

export async function fetchBrandColors(url) {
  return await fetch(`${API_ENDPOINT}/v1/creative_editor/brand_colors/generate?url=${url}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    console.log(data)
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export const getKeywordIdeas = async ({ info }) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/get-keyword-ideas`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({ info }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch keyword ideas');
    }

    const data = await response.json();
    return data.final_keywords;
  } catch (error) {
    console.error('Error fetching keyword ideas:', error);
    throw error;
  }
};

export const getBlogIdeas = async (business_info, keyword_data) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/get_blog_ideas`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({ business_info, keyword_data }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch blog ideas');
    }

    const data = await response.json();
    console.log(data);

    // Ensure we return an array
    return data.blog_ideas;
  } catch (error) {
    console.error('Error fetching blog ideas:', error);
    throw error;
  }
};

export async function listBrandColors() {
  return await fetch(`${API_ENDPOINT}/v1/creative_editor/brand_colors/list`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function getTwilioAuthentication() {
  return await fetch(`${API_ENDPOINT}/v1/twilio/auth`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function getOptimizedMessage(message) {
  return await fetch(`${API_ENDPOINT}/v1/text_optimizer`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      message: message
    }),
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export const authenticateUser = async (email, password) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/end_user/authenticate`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password
      }),
    });

    if (!response.ok) {
      throw new Error('Authentication failed');
    }

    const result = await response.json();

    if (result && result.user_id) {
      localStorage.setItem("userId", result.user_id);
      localStorage.setItem("accessToken", result.token);
      // localStorage.setItem("userRoles", result.roles);
      // localStorage.setItem("accountType", result.account_type);

      return {
        needs_onboarding: result.needs_onboarding || false
      };
    }

    throw new Error('Invalid response from server');
  } catch (error) {
    console.error("Authentication error:", error);
    throw error;
  }
};

export async function submitOnboardingData(onboardingData) {
  console.log("Submitting onboarding data:", onboardingData);
  return await fetch(`${API_ENDPOINT}/v1/onboarding`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify(onboardingData),
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred during onboarding submission.");
      return null;
    }
    const data = await response.json();
    console.log("Onboarding submission response:", data);
    return data;
  }).catch(error => {
    console.error("An error occurred during onboarding submission:", error);
    return null;
  });
}

export const getPaymentDashboardUrl = async() => {
  return await fetch(`${API_ENDPOINT}/v1/payments/dashboard`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred while fetching payment dashboard URL.");
      return null;
    }
    const data = await response.json();
    return data.login_url;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export const checkUserAuthentication = async () => {
  console.log("Checking user authentication...");
  await fetch(`${API_ENDPOINT}/v1/end_user/session/check`, {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      'x-access-token': localStorage.getItem('accessToken')
    },
  })
    .then((res) => {
      if (res.status == 200) return res.json();
      return null;
    })
    .then(async (result) => {
      if (result && result.user_id) {
        localStorage.setItem("userRoles", result.roles);
        localStorage.setItem("accountType", result.account_type);
        localStorage.setItem("needsOnboarding", (result.needs_onboarding || true).toString());
        
        // const needsOnboarding = result.needs_onboarding;
        const needsOnboarding = false;
        const currPathName = window.location.pathname.split('?')[0].split('#')[0];
        
        if (needsOnboarding) {
          if (currPathName !== "/") {
            window.location.href = '/';
          }
        } else if (!result.account_type.includes('root')) {
          if (result.account_type.includes('agency')) {
            if (!allowedPathsByAccountType.agency.includes(currPathName)) {
              window.location.href = "/";
            }
          } else if (result.account_type.includes('subaccount')) {
            if (!allowedPathsByAccountType.subaccount.includes(currPathName)) {
              window.location.href = "/";
            }
          } else {
            window.location.href = '/';
          }
        }
      } else {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("needsOnboarding");
        window.location.href = '/sign-in';
      }
    });
}

export const deauthenticateUser = async() => {
  await fetch(`${API_ENDPOINT}/v1/end_user/session/invalidate`, {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      'x-access-token': localStorage.getItem('accessToken')
    },
  })
    .then((res) => {
      if (res.status == 200) return res.json();
      return null;
    })
    .then(async (result) => {
      localStorage.removeItem("accessToken");
      window.location.href = '';
    });
  
}

export async function fetchDashboardData() {
  try {
    // First get the onboarding data to check active channels
    const onboardingResponse = await submitOnboardingData({});
    console.log("Onboarding data response:", onboardingResponse);

    // Extract active channels and marketing channels from the completed fields
    const marketingChannels = onboardingResponse?.completed_fields?.marketing_channels || [];
    const activeChannels = onboardingResponse?.completed_fields?.active_channels || [];
    const selectedPlans = onboardingResponse?.completed_fields?.selected_plans || [];
    
    // Return formatted dashboard data
    return {
      services: {}, // Keep any existing services data if needed
      marketingChannels: marketingChannels,
      activeChannels: activeChannels,
      selectedPlans: selectedPlans,
      customPricingEnabled: onboardingResponse?.custom_pricing_enabled || false,
      completedFields: onboardingResponse?.completed_fields,
    };
  } catch (error) {
    console.error("Error fetching dashboard data:", error);
    throw error;
  }
}

export async function fetchLeadsDashboardData() {
  return await fetch(`${API_ENDPOINT}/v1/dashboard/data`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function fetchCallLeads() {
  return await fetch(`${API_ENDPOINT}/v1/call_analytics/leads/list`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}


export async function addFormDestination(emailString) {
  return await fetch(`${API_ENDPOINT}/v1/form_submissions/add/destination`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      emails: emailString
    })
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function fetchCombinedLeads() {
  return await fetch(`${API_ENDPOINT}/v1/leads/combined`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data.combined_leads;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function fetchFormLeads() {
  return await fetch(`${API_ENDPOINT}/v1/form_submissions/leads/list`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function getLeadGenerationPreferences() {
  return await fetch(`${API_ENDPOINT}/v1/lead_generation/preferences`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function retrieveSiteInformationForLeadGenerationPreferences(website_url) {
  return await fetch(`${API_ENDPOINT}/v1/lead_generation/preferences/site_scan?website_url=${website_url}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function updateLeadGenerationPreferences(preferences) {
  return await fetch(`${API_ENDPOINT}/v1/lead_generation/preferences`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify(preferences),
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function listUpcomingContent(ucLimit = 10, ucOffset = 0) {
  return await fetch(`${API_ENDPOINT}/v1/upcoming_content/list?uc_limit=${ucLimit}&uc_offset=${ucOffset}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function getUpcomingContentDetails(contentId) {
  return await fetch(`${API_ENDPOINT}/v1/upcoming_content/${contentId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function getReviewManagementPreferences() {
  return await fetch(`${API_ENDPOINT}/v1/review_management/preferences`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}


export async function updateReviewManagementPreferences(preferences) {
  return await fetch(`${API_ENDPOINT}/v1/review_management/preferences`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify(preferences),
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function listReviewRequests(limit = 10, offset = 0) {
  return await fetch(`${API_ENDPOINT}/v1/review_requests/list?limit=${limit}&offset=${offset}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function createReviewRequest(requestData) {
  // console.log(requestData);
  return await fetch(`${API_ENDPOINT}/v1/${requestData.platform}/reviews/request`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      contact_data: requestData.contactData,
      request_type: requestData.requestType,
      custom_message: requestData.customMessage
    }),
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function listReviews(limit = 10, offset = 0) {
  return await fetch(`${API_ENDPOINT}/v1/reviews/list?limit=${limit}&offset=${offset}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function createReviewReply(platform, reviewId, reviewReply) {
  return await fetch(`${API_ENDPOINT}/v1/${platform}/reviews/respond`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      review_id: reviewId,
      review_reply: reviewReply
    }),
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function listMessengerConversations() {
  return await fetch(`${API_ENDPOINT}/v1/meta/messenger/conversations`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function listMessengerConversationMessages(conversationId) {
  return await fetch(`${API_ENDPOINT}/v1/meta/messenger/conversations/${conversationId}/messages`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });

}

export async function listRetentionChats(phoneNumber, authentication, limit = 10, offset = 0) {
  const sortByDate = (a, b) => Date.parse(a.date_created) > Date.parse(b.date_created) ? -1 : 1
  let result = await fetch(`${API_ENDPOINT}/v1/retention/chats/list?limit=${limit}&offset=${offset}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
  return result.chats;
}

export async function listChatMessages(phoneNumber, chatId, authentication, limit = 10, offset = 0) {
  const sortByDate = (a, b) => Date.parse(a.date_created) > Date.parse(b.date_created) ? -1 : 1
  let result = await fetch(`${API_ENDPOINT}/v1/retention/chats/${chatId}/messages?limit=${limit}&offset=${offset}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
  result = (result && result.messages) ? result.messages : [];
  for (var i = 0; i < result.length; i++) {
    result[i].date_created = result[i].date_created?.$date;
    result[i].date_sent = result[i].date_sent?.$date;
    result[i].date_updated = result[i].date_updated?.$date;
  }
  result = result.filter(r => Date.parse(r.date_created) > 1712954525056);
  let messages = result.sort(sortByDate);
  return { messages }
}

export async function markAsRead(messageIds = []) {
  if (messageIds.length > 0) {
    return await fetch(`${API_ENDPOINT}/v1/retention/messages/mark_read`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({
        message_ids: messageIds
      })
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred.");
        return null;
      }
      const data = await response.json();
      return data;
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;
}

export async function getBusinessNumber(authentication) {

  const buildUrl = (accountSid = '', pageSize = 8, pageNumber = 0) =>
    `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/IncomingPhoneNumbers.json?Beta=false&PageSize=${pageSize}&Page=${pageNumber}`

  const getTwilioPhoneNumbers = async (authentication, pageSize = 50, pageNumber = 0) => {
    return await axios.get(
      buildUrl(authentication.accountSid, pageSize, pageNumber),
      { auth: toTwilioCredentials(authentication) }
    )
  }

  let numbers = await getTwilioPhoneNumbers(authentication, 1).data?.incoming_phone_numbers;
  if (numbers != null && numbers.length > 0) {
    return numbers[0];
  }
  return null;
}

export async function sendSMSMessage(to, from, body, authentication, imageURL) {
  // const credentials = toTwilioCredentials(authentication);
  // const data = new URLSearchParams()
  // data.append('To', to)
  // data.append('From', from)
  // data.append('Body', body)
  // // imageURL = "https://www.webfx.com/wp-content/uploads/2023/07/what-is-openai.png"
  // console.log(imageURL)
  // if (imageURL != null) {
  //   data.append('MediaUrl', imageURL)
  // }
  // const url = `https://api.twilio.com/2010-04-01/Accounts/${authentication.accountSid}/Messages.json`
  // let messageResponse = await axios.post(url, data, {
  //   auth: credentials,
  //   headers: {
  //     'Content-Type': 'application/x-www-form-urlencoded'
  //   },
  // });

  // return messageResponse.data;
  console.log(to, body, "TESTING (after)");
  return await fetch(`${API_ENDPOINT}/v1/retention/${to}/message/send`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      msg_body: body,
      msg_image: imageURL,
    })
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return {
        status: false,
        message: "An error occurred.",
        data: null
      }
    }
    const data = await response.json();
    return {
      status: true,
      message: "",
      data: data
    }
  }).catch(error => {
    console.log("An error occurred:", error);
    return {
      status: false,
      message: error,
      data: null
    }
  });
}

export async function sendBlastSMSMessage(cgId, blastMessage, imageURL) {
  return await fetch(`${API_ENDPOINT}/v1/retention/blast_message`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      msg_body: blastMessage,
      msg_image: imageURL,
      cg_id: cgId
    })
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function importCustomerCSVList(cgId, fileObj, smsColId = "", emailColId = "") {
  const formData = new FormData();
  formData.append('file', fileObj);
  // build import url
  let importURL = `${API_ENDPOINT}/v1/retention/csv/customers/import?cg_id=${cgId}`;
  if (smsColId != null && smsColId != "") {
    importURL = `${importURL}&sms_col_id=${smsColId}`;
  }
  if (emailColId != null && emailColId != "") {
    importURL = `${importURL}&email_col_id=${emailColId}`;
  }

  return await fetch(importURL, {
    method: 'POST',
    headers: {
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: formData,
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function listRetentionCustomerGroups() {
  let listURL = `${API_ENDPOINT}/v1/retention/customer_groups/list`;
  return await fetch(listURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function listRetentionCustomers(cgId, limit = 10, offset = 0) {
  let listURL = `${API_ENDPOINT}/v1/retention/customers/list?cg_id=${cgId}&offset=${offset}`;
  console.log(limit, "TESTING");
  if (limit != null) {
    listURL = `${listURL}&limit=${limit}`
  }
  return await fetch(listURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function listRetentionCustomerColumns(cgId) {
  let listURL = `${API_ENDPOINT}/v1/retention/customer/columns/list?cg_id=${cgId}`;
  return await fetch(listURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function getCreativeEditorGraphicTemplates(limit = 10, offset = 0) {
  let listURL = `${API_ENDPOINT}/v1/creative_editor/graphic_templates/list?offset=${offset}`;
  if (limit != null) {
    listURL = `${listURL}&limit=${limit}`
  }
  return await fetch(listURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function listRetentionCampaigns() {
  let listURL = `${API_ENDPOINT}/v1/retention/campaigns/list`;
  return await fetch(listURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return [];
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return [];
  });
}

export async function getRetentionCampaign(campaignId) {
  if (campaignId != null) {
    let getURL = `${API_ENDPOINT}/v1/retention/campaign/retrieve?campaign_id=${campaignId}`;
    return await fetch(getURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred.");
        return null;
      }
      const data = await response.json();
      return data;
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;
}

export async function createRetentionCampaign(flow, schedule, campaignName, campaignId, launchCampaign) {
  if (flow != null) {
    return await fetch(`${API_ENDPOINT}/v1/retention/campaign/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({
        flow: flow,
        schedule: schedule,
        campaign_id: campaignId,
        campaign_name: campaignName,
        launch_campaign: launchCampaign
      })
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred.");
        return null;
      }
      const data = await response.json();
      return data;
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;

}

export async function listCreativeEditorProjects() {
  let listURL = `${API_ENDPOINT}/v1/creative_editor/projects/list`;
  return await fetch(listURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function processCreativeEditorFonts(limit, offset, query) {
  let loadableFonts = CUSTOM_FONTS.slice().sort((a, b) => a.font_family.localeCompare(b.font_family));
  if (query != null && query != "") {
    loadableFonts = loadableFonts.filter(font => font.font_family.toLowerCase().includes(query.toLowerCase()));
  }
  let total_fonts = loadableFonts.length;
  let listURL = `${API_ENDPOINT}/v1/creative_editor/assets/fonts/process`;
  return await fetch(listURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      font_assets: loadableFonts.splice(offset, offset + limit)
    })
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    data.total_fonts = total_fonts;
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function listCreativeEditorFontAssets(limit, offset, query) {
  let listURL = "";
  if (query == null || query == "") {
    listURL = `${API_ENDPOINT}/v1/creative_editor/assets/fonts/list?limit=${limit}&offset=${offset}`;
  } else {
    listURL = `${API_ENDPOINT}/v1/creative_editor/assets/fonts/search?limit=${limit}&offset=${offset}&query=${query}`;
  }
  return await fetch(listURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function listCreativeEditorAssets(assetType) {
  let listURL = `${API_ENDPOINT}/v1/creative_editor/assets/list?asset_type=${assetType}`;
  return await fetch(listURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function getCreativeEditorProject(projectId) {
  let getURL = `${API_ENDPOINT}/v1/creative_editor/projects/${projectId}`;
  return await fetch(getURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function createCreativeEditorFontAsset(assetName, assetFamily, assetPostscript, assetCategory, assetURL, assetDataURL, assetFilename) {
  let createURL = `${API_ENDPOINT}/v1/creative_editor/assets/fonts/create`;
  return await fetch(createURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      asset_name: assetName,
      asset_family: assetFamily,
      asset_postscript: assetPostscript,
      asset_category: assetCategory,
      asset_url: assetURL,
      asset_data_url: assetDataURL,
      asset_filename: assetFilename
    })
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function createCreativeEditorAsset(assetData, assetPreviewDataUrl, assetKeywords, assetType) {
  let createURL = `${API_ENDPOINT}/v1/creative_editor/assets/create`;
  return await fetch(createURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      asset_data: assetData,
      preview_data_url: assetPreviewDataUrl,
      asset_type: assetType,
      asset_keywords: assetKeywords
    })
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function createCreativeEditorProject(projectName) {
  let createURL = `${API_ENDPOINT}/v1/creative_editor/projects/create`;
  return await fetch(createURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      project_name: projectName
    })
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function updateCreativeEditorProject(projectId, projectName, editorState, previewDataUrl) {
  let updateURL = `${API_ENDPOINT}/v1/creative_editor/projects/${projectId}`;
  return await fetch(updateURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      project_name: projectName,
      editor_state: editorState,
      preview_data_url: previewDataUrl
    })
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function generateTextCreative(prompt, type) {
  if (prompt != null) {
    return await fetch(`${API_ENDPOINT}/v1/creative_editor/generate_text`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({
        prompt: prompt,
        type: type
      })
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred.");
        return null;
      }
      const data = await response.json();
      return data;
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;

}
export async function generateImageCreative(prompt, type) {
  if (prompt != null) {
    return await fetch(`${API_ENDPOINT}/v1/creative_editor/generate_image`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({
        prompt: prompt,
        type: type
      })
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred.");
        return null;
      }
      const data = await response.json();
      return data;
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;

}

export async function generateUpscaledImageCreative(prompt, type) {
  if (prompt != null) {
    return await fetch(`${API_ENDPOINT}/v1/creative_editor/generate_image`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({
        prompt: prompt,
        type: type
      })
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred.");
        return null;
      }
      const data = await response.json();
      return data;
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;

}

export async function createUpcomingContent(contentType, platform, imagesContent, textContent, metadata) {
  if (platform != null && imagesContent != null && textContent != null && (imagesContent.length > 0 || textContent != "")) {
    return await fetch(`${API_ENDPOINT}/v1/upcoming_content/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({
        platform,
        content_type: contentType,
        images_content: imagesContent,
        text_content: textContent,
        metadata
      })
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred.");
        return null;
      }
      const data = await response.json();
      return data;
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;
}

export async function respondToMetaPagePostComment(commentId, textContent) {
  if (textContent != null && textContent != "" && commentId && commentId != "") {
    return await fetch(`${API_ENDPOINT}/v1/meta/posts/comments/${commentId}/respond`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({
        text_content: textContent
      })
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred.");
        return null;
      }
      const data = await response.json();
      return data;
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;
}

export async function respondToInstaPagePostComment(commentId, textContent) {
  if (textContent != null && textContent != "" && commentId && commentId != "") {
    return await fetch(`${API_ENDPOINT}/v1/insta/posts/comments/${commentId}/respond`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({
        text_content: textContent
      })
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred.");
        return null;
      }
      const data = await response.json();
      return data;
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;
}

export async function sendMessengerMessage(recipientId, pageId, textContent) {
  if (textContent != null && textContent != "" && recipentId && recipentId != "" && pageId && pageId != "") {
    return await fetch(`${API_ENDPOINT}/v1/meta/${pageId}/messenger/${recipientId}/reply`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({
        text_content: textContent
      })
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred.");
        return {
          status: false,
          message: "An error occurred.",
          data: null
        }
      }
      const data = await response.json();
      return {
        status: true,
        message: "",
        data: data
      }
    }).catch(error => {
      console.log("An error occurred:", error);
      return {
        status: false,
        message: "An error occurred.",
        data: null
      }
    });
  }
  return {
    status: false,
    message: "An error occurred.",
    data: null
  }
}

export async function getIntegrationsStatus() {
  let statusURL = `${API_ENDPOINT}/v1/integrations/statuses`;
  return await fetch(statusURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function handleMetaOAuth() {
  let oauthURL = `${API_ENDPOINT}/v1/meta/oauth`;
  return await fetch(oauthURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    if (data.redirect_url) {
      window.location.href = data.redirect_url;
    }
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });

}

export async function handleGoogleOAuth() {
  let oauthURL = `${API_ENDPOINT}/v1/google/oauth`;
  return await fetch(oauthURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    if (data.redirect_url) {
      window.location.href = data.redirect_url;
    }
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });

}

export async function listMediaLibrary(limit = 10, offset = 0) {
  let listURL = `${API_ENDPOINT}/v1/media_library/list?limit=${limit}&offset=${offset}`;
  return await fetch(listURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function deleteMediaLibraryItem(itemId) {
  let deleteURL = `${API_ENDPOINT}/v1/media_library/delete`;
  return await fetch(deleteURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({ item_id: itemId })
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function uploadMediaLibraryItem(file) {
  const formData = new FormData();
  formData.append('file', file);

  let uploadURL = `${API_ENDPOINT}/v1/media_library/upload`;
  try {
    const response = await fetch(uploadURL, {
      method: 'POST',
      headers: {
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: formData
    });

    if (!response.ok) {
      console.log("An error occurred during upload.");
      return { success: false };
    }

    const data = await response.json();
    return { success: true, data };
  } catch (error) {
    console.log("An error occurred:", error);
    return { success: false };
  }
}

export async function searchMediaLibrary(queryText) {
  let searchURL = `${API_ENDPOINT}/v1/media_library/search`;
  return await fetch(searchURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({ query_text: queryText })
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred during search.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function createStripePaymentIntent(amount, plan) {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/stripe/create_subscription_intent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({ amount, selected_plan: plan }),
    });

    console.log(response);

    if (!response.ok) {
      throw new Error('Failed to create payment intent');
    }

    const data = await response.json();
    return {
      clientSecret: data.clientSecret,
      subscriptionId: data.subscriptionId
    };
  } catch (error) {
    console.error('Error creating payment intent:', error);
    throw error;
  }
}

export async function constructRetentionCampaignChat(messages, attachments) {
  if (messages && messages.length > 0) {
    const formData = new FormData(); // Create a FormData object to handle file uploads

    let lastNonNullNewGeneration = null;
    // const allMessages = JSON.stringify(messages.map(msg => {
    //   if (msg.sender !== 'user' && msg.metadata?.newGeneration) {
    //     lastNonNullNewGeneration = msg.metadata.newGeneration; // Update the last non-null campaignFlow
    //   }
    //   return {
    //     role: msg.sender === 'user' ? 'user' : 'assistant',
    //     content: msg.sender === 'user' ? msg.text : (msg.metadata?.newGeneration || lastNonNullNewGeneration)
    //   };
    // }));

    const allMessages = JSON.stringify(messages.map(msg => {
      return {
        role: msg.sender === 'user' ? 'user' : 'assistant',
        content: msg.text 
      };
    }));

    formData.append('messages', allMessages);
    
    // Append each file to the FormData object
    attachments.forEach(file => {
      formData.append('files', file); // Use 'files' as the key for the attachments
    });

    return await fetch(`${API_ENDPOINT}/v1/retention/campaign/construct/chat`, {
      method: 'POST',
      headers: {
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: formData // Use FormData as the body
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred while constructing the retention campaign chat.");
        return null;
      }
      const data = await response.json();
      return data.campaign_flow;
      // return {campaignFlow: data.campaign_flow, newGeneration: data.new_generation};
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;
}

export async function listEmailTemplatesRetentionCampaign(limit = 10, continuationToken = null) {
  const url = new URL(`${API_ENDPOINT}/v1/retention/campaign/email_utility/list_templates`);
  url.searchParams.append('limit', limit);
  if (continuationToken) {
    url.searchParams.append('continuation_token', continuationToken);
  }

  return await fetch(url.toString(), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred while listing email templates.");
      return null;
    }
    const data = await response.json();
    return {
      templates: data.templates,
      nextToken: data.next_token
    };
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function retrieveEmailTemplateRetentionCampaign(templateId) {
  if (!templateId) {
    console.log("No template_id provided.");
    return null;
  }

  return await fetch(`${API_ENDPOINT}/v1/retention/campaign/email_utility/retrieve_template?template_id=${encodeURIComponent(templateId)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred while retrieving the email template.");
      return null;
    }
    const data = await response.json();
    return data.template_content;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function applyCampaignEmailInstructions(campaignId, emailContent, instruction, selectedElementIndex, emailContentHTML) {
  if (campaignId && emailContent && instruction && selectedElementIndex !== undefined) {
    const requestBody = {
      campaign_id: campaignId,
      email_content: emailContent,
      email_content_html: emailContentHTML,
      instruction,
      selected_element_index: selectedElementIndex
    };

    return await fetch(`${API_ENDPOINT}/v1/retention/campaign/email_utility/apply_changes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify(requestBody)
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred while applying campaign email instructions.");
        return null;
      }
      const data = await response.json();
      return data;
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;
}

export async function applyWebsiteBuilderChanges(campaignId, pageContent, instruction, selectedElementIndex, pageContentHTML) {
  if (campaignId && pageContent && instruction && selectedElementIndex !== undefined) {
    const requestBody = {
      campaign_id: campaignId,
      page_content: pageContent,
      page_content_html: pageContentHTML,
      instruction,
      selected_element_index: selectedElementIndex
    };

    return await fetch(`${API_ENDPOINT}/v1/website_builder/apply_changes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify(requestBody)
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred while applying website change instructions.");
        return null;
      }
      const data = await response.json();
      return data;
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;
}

export async function updateHeaderFooter(campaignId, instruction, pageType, oldContentHTML, currPages) {
  if (campaignId && instruction && pageType) {
    const requestBody = {
      campaign_id: campaignId,
      page_type: pageType,
      old_content_html: oldContentHTML,
      current_pages: currPages,
      instruction: `${instruction.replace(/\.$/, '')}.`
    };
    console.log("Updating header footer:", requestBody);
    return await fetch(`${API_ENDPOINT}/v1/website_builder/header_footer/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify(requestBody)
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred while generating website page.");
        return null;
      }
      const data = await response.json();
      return data;
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;
}

export async function generateFullWebsite(instruction, brandingType, brandInfo, themeBackground) {
  if (instruction && brandingType && brandInfo) {
    const requestBody = {
      instruction,
      branding_type: brandingType,
      brand_info: brandInfo,
      
      theme_background: themeBackground
    };

    return await fetch(`${API_ENDPOINT}/v1/website_builder/generate_full_website`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify(requestBody)
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred while generating the website.");
        return null;
      }
      const data = await response.json();
      return data;
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;
}

export async function generateWebsiteBuilderPage(campaignId, instruction, pageName, brandingType, brandInfo) {
  if (campaignId &&  brandingType && brandInfo && instruction && pageName) {
    const requestBody = {
      campaign_id: campaignId,
      page_name: pageName,
      branding_type: brandingType,
      brand_info: brandInfo,
      instruction: `${instruction.replace(/\.$/, '')} DO NOT GENERATE A NAV BAR / HEADER OR FOOTER`
    };

    return await fetch(`${API_ENDPOINT}/v1/website_builder/generate_page`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify(requestBody)
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred while generating website page.");
        return null;
      }
      const data = await response.json();
      return data;
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;
}


export async function reGenerateWebsiteBuilderPage(campaignId, instruction, existingPage) {
  if (campaignId && instruction && existingPage) {  // Fixed condition check
    const requestBody = {
      campaign_id: campaignId,
      existing_page: existingPage,
      instruction: `${instruction.replace(/\.$/, '')} DO NOT GENERATE A NAV BAR / HEADER OR FOOTER. DO NOT CHANGE ANYTHING ELSE THAT I HAVE NOT INSTUCTED TO YOU.`
    };

    return await fetch(`${API_ENDPOINT}/v1/website_builder/regenerate_page`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify(requestBody)
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred while generating website page.");
        return null;
      }
      const data = await response.json();
      return data;
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;
}

export const createUser = async (firstName, lastName, email, password, selectedPlans, accountType = 'root', parentUserId = null, promoCode = null) => {
  console.log(firstName, lastName, email, password, accountType, parentUserId, selectedPlans);
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/end_user/create`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        account_type: accountType,
        parent_user_id: parentUserId,
        selected_plans: selectedPlans,
        promo_code: promoCode
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to create user');
    }

    const result = await response.json();
    
    if (result && result.user_id) {
      return {
        success: true,
        userId: result.user_id,
        message: result.message,
        needsOnboarding: result.needs_onboarding,
        subscriptionId: result.subscription_id,
        clientSecret: result.client_secret
      };
    }

    throw new Error('Invalid response from server');
  } catch (error) {
    console.error("User creation error:", error);
    return {
      success: false,
      message: error.message || 'Failed to create user'
    };
  }
};

export async function listAvailablePhoneNumbers(areaCode) {
  let listURL = `${API_ENDPOINT}/v1/phone_services/numbers/list?area_code=${areaCode}`;
  return await fetch(listURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data.available_numbers;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function getEmailAuthentication() {
  return await fetch(`${API_ENDPOINT}/v1/email_services/authentication`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred while retrieving the email authentication.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function registerEmailDomain(domain) {
  return await fetch(`${API_ENDPOINT}/v1/email_services/domain/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      domain: domain
    }),
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred registering email domain.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function bulkUploadMediaLibraryItems(files) {
  const formData = new FormData();
  files.forEach(file => formData.append('files', file));

  let uploadURL = `${API_ENDPOINT}/v1/media_library/bulk_upload`;
  try {
    const response = await fetch(uploadURL, {
      method: 'POST',
      headers: {
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: formData
    });

    if (!response.ok) {
      console.log("An error occurred during bulk upload.");
      return { success: false };
    }

    const data = await response.json();
    return { success: true, data };
  } catch (error) {
    console.log("An error occurred:", error);
    return { success: false };
  }
}

export async function extractHeaderFooter(pageContentHTML, pagesStructure) {
  if (!pageContentHTML) {
    console.log("Missing parameters");
    return null;
  }

  return await fetch(`${API_ENDPOINT}/v1/website_builder/header_footer/extract`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      page_content_html: pageContentHTML,
      pages_structure: pagesStructure
    })
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function generateCampaignEmailContent(emailType, instructions, cgId, images=[]) {
  if (emailType && instructions) {
    const requestBody = {
      email_type: emailType,
      instructions: instructions,
      cg_id: cgId ? cgId : null,
      images: images
    };

    return await fetch(`${API_ENDPOINT}/v1/retention/campaign/email_utility/generate_email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify(requestBody)
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred while generating the campaign email.");
        return null;
      }
      const data = await response.json();
      return data;
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;
}

export async function retrieveBusinessWebsite() {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/website_management/retrieve_site`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
    });

    if (!response.ok) {
      console.log("An error occurred while retrieving the website draft.");
      return null;
    }

    const data = await response.json();
    return data.website;
  } catch (error) {
    console.error("An error occurred:", error);
    return null;
  }
}

export async function saveBusinessWebsite(pages, sitePublished, deletedPages = [], brandingType = null, brandInfo = null) {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/website_management/save`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({
        pages: pages,
        site_published: sitePublished,
        deleted_pages: deletedPages,
        site_branding_type: brandingType,
        site_brand_info: brandInfo
      })
    });

    if (!response.ok) {
      console.log("An error occurred while saving the website draft.");
      return null;
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("An error occurred:", error);
    return null;
  }
}

export async function getRetentionCampaignStats(campaignId) {
  if (!campaignId) {
    console.log("No campaign_id provided.");
    return null;
  }

  return await fetch(`${API_ENDPOINT}/v1/retention/campaign/stats?campaign_id=${encodeURIComponent(campaignId)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred while retrieving the campaign stats.");
      return null;
    }
    const data = await response.json();
    return data.stats;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function viewBookingRequests() {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/booking/requests`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch booking requests');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching booking requests:", error);
    return null;
  }
}

export async function handleBookingRequest(requestId, action, denialReason) {
  try {
    const body = { action };
    if (action === 'deny' && denialReason) {
      body.denial_reason = denialReason;
    }

    const response = await fetch(`${API_ENDPOINT}/v1/booking/request/${requestId}/action`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error('Failed to process booking request');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error processing booking request:", error);
    return null;
  }
}

export async function createBookingLink(bookingConfig) {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/booking/links`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify(bookingConfig),
    });

    if (!response.ok) {
      throw new Error('Failed to create booking link');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error creating booking link:", error);
    return null;
  }
}

export async function getBookingLinks() {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/booking/links`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch booking links');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching booking links:", error);
    return null;
  }
}

export async function updateBookingLink(bookingId, bookingConfig) {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/booking/links/${bookingId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify(bookingConfig),
    });

    if (!response.ok) {
      throw new Error('Failed to update booking link');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating booking link:", error);
    return null;
  }
}

export async function listBusinessReferrals() {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/referrals/business/list`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch referrals');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching referrals:", error);
    return null;
  }
}

export async function getReferralConfiguration() {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/referral/configuration/get`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch referral configuration');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching referral configuration:", error);
    return null;
  }
}

export async function createReferralConfiguration(config) {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/referral/configuration`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify(config),
    });

    if (!response.ok) {
      throw new Error('Failed to create referral configuration');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error creating referral configuration:", error);
    return null;
  }
}

export async function listBusinessBookings(startDate, endDate) {
  try {
    let url = `${API_ENDPOINT}/v1/bookings/list`;
    const params = new URLSearchParams();
    
    if (startDate) params.append('start_date', startDate);
    if (endDate) params.append('end_date', endDate);
    
    if (params.toString()) {
      url += `?${params.toString()}`;
    }

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch bookings');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching bookings:", error);
    return null;
  }
}

export async function cancelBooking(appointmentId) {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/booking/${appointmentId}/cancel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
    });

    if (!response.ok) {
      throw new Error('Failed to cancel booking');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error canceling booking:", error);
    return null;
  }
}

export async function rescheduleBooking(appointmentId, newTime) {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/booking/${appointmentId}/reschedule`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({
        new_time: newTime
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to reschedule booking');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error rescheduling booking:", error);
    return null;
  }
}

export async function generateWebsiteBlueprint(websiteData) {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/website_builder/upload_website_blueprint`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({blueprint_data: websiteData}),
    });
  } catch (error) {
    console.error("Error generating website blueprint:", error);
    return null;
  }
}

export async function checkWebsiteBlueprintStatus(blueprintId) {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/website_builder/check_website_blueprint`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
    });

    if (!response.ok) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    console.error("Error checking website blueprint status:", error);
    return null;
  }
}

export async function checkPromoCodeDiscount(promoCode) {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/promo_codes/check`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({
        promo_code: promoCode
      }),
    });

    if (!response.ok) {
      return null;
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error checking promo code:", error);
    return null;
  }
}

export async function getMerchantAccountLink() {
  const response = await fetch(`${API_ENDPOINT}/v1/merchant/account/link`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  });
  
  if (!response.ok) {
    throw new Error('Failed to fetch link token');
  }
  
  const data = await response.json();
  if (data.success && data.link_token) {
    return data.link_token;
  } else {
    return null;
  }
}

export async function getPlaidLinkToken() {
  const response = await fetch(`${API_ENDPOINT}/v1/merchant/account/link`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  });
  
  if (!response.ok) {
    throw new Error('Failed to fetch link token');
  }
  
  const data = await response.json();
  if (data.success) {
    return data;
  } else {
    return null;
  }
}

export async function sendPlaidLinkedAccountData(accountId, publicToken) {
  const response = await fetch(`${API_ENDPOINT}/v1/merchant/account/link/callback`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      account_id: accountId,
      public_token: publicToken
    }),
  });
  
  if (!response.ok) {
    throw new Error('Failed to fetch link token');
  }
  
  const data = await response.json();
  if (data.success) {
    return true;
  } else {
    return false;
  }
}